
#content {
  padding-top: 80px;
  padding-bottom: 0;
}

.homepage-banner {
  width: 100%;
  height: calc(100vh - 80px);
  overflow: hidden;
  margin: 0 !important;
  padding: 0;
  z-index: 20;

  // Styling the default Slick dots
  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    list-style-type: none;
    position: absolute;
    bottom: 10px;
    right: 20%;
    z-index: 1000;
    width: 80%;
    transform: translate(-35%, 0);

    li {
      margin: 0 0.25rem;
    }

    button {
      display: block;
      width: 10px;
      height: 10px;
      padding: 0;

      border: 1px solid #ccc;
      border-radius: 0;
      background-color: rgb(255 255 255 / 60%);

      text-indent: -9999px;

      &:hover {
        color: white
      }
    }

    li.slick-active button {
      background-color: white;
      opacity: 1;
    }

  }

  .outer-content {
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    height: calc(100vh - 80px);
    width: 100%;

    .img-slick, .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .img-info {
      width: 80%;
      z-index: 1000;
      height: calc(100% - 60px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .caption-content {
        display: flex;

        img {
          max-width: 60px;
        }

        h2 {
          font-size: 16px !important;
          text-transform: uppercase;
          color: #fff;
          font-family: 'Klavika-Bold', sans-serif;
          width: auto;
          height: 60px;
          text-align: left;
          margin: 0;
          line-height: 100%;
          display: flex;
          word-spacing: calc(100vw - 60px);
          /* vertical-align: middle; */
          padding: 18px 0 0 20px;

        }

      }

      h1 {
        line-height: 1;
        font-family: 'Roboto', sans-serif;
        color: #fff;
        text-align: left;
        font-size: 50px;
        width: 100%;
        display: inline-block;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 2rem;
        margin-top: 3rem;
        text-rendering: optimizeLegibility;
      }
    }

    .video-info {
      position: absolute;
      right: 3rem;
      bottom: 3rem;

      .custom-play-icon {
        background-color: rgba(51, 62, 72, 0.8);
        display: block;
        height: 50px;
        width: 50px;
        float: left;
        margin-right: 5px;
        z-index: 5;
        border: 0;
        border-radius: 50%;
        padding: 0;
        cursor: pointer;

        .arrow-right {
          width: 0;
          height: 0;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-left: 18px solid #2cada0;
          margin: 50% 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }

      .custom-sound-ctrl {
        background-color: rgba(51, 62, 72, 0.8);
        display: block;
        height: 50px;
        width: 50px;
        margin-left: 5px;
        border: 0;
        border-radius: 50%;
        padding: 0;
        cursor: pointer;
        color: #2cada0;
        float: right;

        i {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          font-size: 20px;
          display: inline-block;
        }
      }

      .sound-on {
      }
    }
  }

}

@media only screen and (max-width: 767.98px) {
  .homepage-banner {
    .inner-content {
      padding: 20px;
      width: 300px;

      .title {
        font-size: 30px;
        width: 100%;
      }

      .subtitle {
        font-size: 18px;
        width: 100%;
      }
    }
  }

  .outer-content {
    img, video {
      object-fit: cover;
    }
  }
}
